<template>
    <v-img
        :src="src"
        :width="width"
        :height="height"
        transition="false"
    >
    <template v-slot:placeholder>
        <div class="d-flex align-center justify-center fill-height">
        <v-progress-circular
            color="grey-lighten-4"
            indeterminate
        ></v-progress-circular>
        </div>
    </template>
    </v-img>
</template>
  
<script>
  export default {
    name: "PGImg",
    props: {
      width: { type: [String, Number], default: null },
      height: { type: [String, Number], default: null },
      src: { type: String, default: "" },
    },
  };
</script>

  