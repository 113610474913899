import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "@/plugins/vuetify";
//import { registerPlugins } from '@/plugins/vuetify'
import { createPinia, PiniaVuePlugin } from "pinia";
import i18n from "./i18n";
import VueCryptojs from "vue-cryptojs";
import mixins from "@/plugins/mixins";
import Dashboard from "@/components/layouts/Dashboard";
import BaseLayout from "@/components/layouts/BaseLayout";
import NoneLayout from "@/components/layouts/NoneLayout";
import CompanyLayout from "@/components/layouts/Company";
import GameLayout from "@/components/layouts/GameLayout";
import GameDetailLayout from "@/components/layouts/GameDetailLayout";
import GameSubDetailLayout from "@/components/layouts/GameSubDetailLayout";
import PGAlert from "@/components/PGAlert";
import PGAlertGroup from "@/components/PGAlertGroup";
import PGDialogConfirm from "@/components/PGDialogConfirm";
import PGTable from "@/components/PGTable";
import PGGameStatus from "@/components/PGGameStatus";
import PGUrlNumber from "@/components/PGUrlNumber";
import PGAlertMessage from "@/components/PGAlertMessage";
import PGBreadcrumbGame from "@/components/PGBreadcrumbGame";
import PGTemplateImage from "@/components/PGTemplateImage";
import PGUploadImg from "@/components/PGUploadImg";
import PGPublicUrl from "@/components/PGPublicUrl";
import VueQRCodeComponent from "vue-qrcode-component";
//import VueMask from "v-mask";
import { vMaska } from "maska"
import MainLayout from "@/components/layouts";
import PGPaymentLoading from "@/components/PGPaymentLoading";
import PGCardHeader from "@/components/PGCardHeader";
import PGIconBtn from "@/components/PGIconBtn";
import PGScoreChips from "@/components/PGScoreChips";
import PGBreadcrumbs from "@/components/PGBreadcrumbs";
import PGTextarea from "@/components/PGTextarea";
import PGTextField from "@/components/PGTextField";
import PGSelect from "@/components/PGSelect";
import PGAutocomplete from "@/components/PGAutocomplete";
import DialogUpload from "@/components/DialogUpLoadImg";
import CropperImg from "@/components/CropperImg/CropperImg.vue"
import 'cropperjs/dist/cropper.css';
import VueCropper from 'vue-cropperjs';
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import AlertNote from '@/components/AlertNoteAndPlan'
import "./assets/scss/main.scss";
import { VueDraggableNext } from 'vue-draggable-next'
import PGTreeview from "@/components/PGTreeview"
import PGTooltip from "@/components/PGTooltip"
// import moment from 'moment-timezone';
import PGImg from "@/components/PGImg";

// Set default timezone to JST
// moment.tz.setDefault("Asia/Tokyo");

const app = createApp(App);

app.component("p-treeview", PGTreeview);
app.component("dash-board", Dashboard);
app.component("base-layout", BaseLayout);
app.component("company-layout", CompanyLayout);
app.component("none-layout", NoneLayout);
app.component("game-layout", GameLayout);
app.component("game-detail-layout", GameDetailLayout);
app.component("game-sub-detail-layout", GameSubDetailLayout);
app.component("main-layout", MainLayout);
app.component("p-alert", PGAlert);
app.component("p-alert-group", PGAlertGroup);
app.component("p-dialog-confirm", PGDialogConfirm);
app.component("p-table", PGTable);
app.component("p-game-status", PGGameStatus);
app.component("p-url-number", PGUrlNumber);
app.component("p-alert-message", PGAlertMessage);
app.component("p-breadcrumb-game", PGBreadcrumbGame);
app.component("qr-code", VueQRCodeComponent);
app.component("p-template-image", PGTemplateImage);
app.component("p-upload-image", PGUploadImg);
app.component("p-public-url", PGPublicUrl);
app.component("p-payment-loading", PGPaymentLoading);
app.component("p-card-header", PGCardHeader);
app.component("p-icon-btn", PGIconBtn);
app.component("p-score-chips", PGScoreChips);
app.component("p-breadcrumbs", PGBreadcrumbs);
app.component("p-textarea", PGTextarea);
app.component("p-text-field", PGTextField);
app.component("p-select", PGSelect);
app.component("p-autocomplete", PGAutocomplete);
app.component("dialog-upload", DialogUpload);
app.component("CropperImg", CropperImg);
app.component("AlertNote", AlertNote);
app.component("p-tooltip", PGTooltip);
app.component("p-img", PGImg);

// eslint-disable-next-line vue/multi-word-component-names
app.component("draggable", VueDraggableNext);
app.use(PiniaVuePlugin);
app.use(VueCryptojs);
app.directive("maska", vMaska);
//app.use(VueMask);
app.component(VueCropper);
app.use(LoadingPlugin);
const pinia = createPinia();
app.config.productionTip = false;
app.use(pinia);
app.use(vuetify);
app.mixin(mixins);
app.use(router);
app.use(i18n);

//registerPlugins(app);

router.isReady().then(() => app.mount('#app'));
